import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './TextSvg.module.scss';

import { ReactComponent as Circle001 } from './assets/001.svg';
import { ReactComponent as Circle002 } from './assets/002.svg';
import { ReactComponent as Circle003 } from './assets/003.svg';
import { ReactComponent as Circle004 } from './assets/004.svg';
import { ReactComponent as Square001 } from './assets/Square001.svg';
import { ReactComponent as Square002 } from './assets/Square002.svg';
import { ReactComponent as Square003 } from './assets/Square003.svg';
import { ReactComponent as Square004 } from './assets/Square004.svg';
import { ReactComponent as Square005 } from './assets/Square005.svg';
import { ReactComponent as Square006 } from './assets/Square006.svg';
import { ReactComponent as MusicLinkIcon } from './assets/music-link.svg';
const SvgKeys = {
  Circle001,
  Circle002,
  Circle003,
  Circle004,
  Square001,
  Square002,
  Square003,
  Square004,
  Square005,
  Square006,
  MusicLinkIcon,
};

const TextSvg = ({
  children,
  svg,
  svgClipName,
  tagEl: TagEl = 'div',
  className,
  innerClassName,
  style = {},
  image,
  containImage,
  video,
  audio,
  colorHover,
  ...props
}) => {
  const videoRef = useRef(null);
  const audioRef = useRef(null);

  const clip = useMemo(() => {
    return svgClipName ? { '--clip-name': `url(#${svgClipName})` } : {};
  }, [svgClipName]);

  const SvgEl = useMemo(() => {
    if (typeof svg === 'function') {
      return svg;
    }

    return SvgKeys[svg];
  }, [svg]);

  const onMouseEnter = useCallback(() => {
    const { matches } = window.matchMedia('hover: none');

    try {
      if (!matches) {
        if (videoRef.current) {
          videoRef.current.play();
        }
        if (audioRef.current) {
          audioRef.current.play();
        }
      }
    } catch {
      //nothing
    }
  }, []);

  const onMouseLeave = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
    if (audioRef.current) {
      audioRef.current.pause();
    }
  }, []);

  return (
    <TagEl
      className={classNames(
        styles.outer,
        { [styles.withImage]: image },
        { [styles.containImage]: containImage },
        { [styles.colorHover]: colorHover },
        className,
      )}
      style={{ ...style, ...clip }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...props}
    >
      <SvgEl className={styles.svg} />
      {image && <div className={styles.media} style={{ backgroundImage: `url(${image})` }} />}
      {video && <video ref={videoRef} muted loop className={styles.media} src={video} />}
      {audio && <audio ref={audioRef} loop className={styles.audio} src={audio} />}
      <div className={classNames(styles.inner, innerClassName)}>{children}</div>
    </TagEl>
  );
};

TextSvg.propTypes = {
  tagEl: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.object]),
  svg: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
};

export { TextSvg };

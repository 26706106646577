import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Wrapper.module.scss';

const Wrapper = ({
  tagName: El = 'div',
  children,
  className,
  innerClassName,
  border = false,
  padded = false,
  doublePadded = false,
  wrapped = false,
  ...props
}) => {
  const cls = classNames(
    styles.wrapper,
    { [styles.border]: border },
    { [styles.padded]: padded },
    { [styles.doublePadded]: doublePadded },
  );

  if (wrapped) {
    return (
      <El className={classNames(cls, styles.wrapped, className)} {...props}>
        <div className={classNames(styles.inner, innerClassName)}>{children}</div>
      </El>
    );
  }

  return (
    <El className={classNames(cls, className)} {...props}>
      {children}
    </El>
  );
};

Wrapper.propTypes = {
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  tagName: PropTypes.string,
  wrapped: PropTypes.bool,
  border: PropTypes.bool,
  padded: PropTypes.bool,
  doublePadded: PropTypes.bool,
};

export { Wrapper };

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { InternalExternalLink } from '../InternalExternalLink';
import { Icon } from '../Icon/Icon';
import styles from './Button.module.scss';

const ButtonInner = ({ icon, children }) => {
  return (
    <>
      {icon && <Icon className={styles.icon} name={icon} />}
      {children}
    </>
  );
};

const Button = ({ to, onClick, className, children, icon, disabled, size = 'normal', ...props }) => {
  const cls = classNames(
    styles.button,
    styles[size],
    { [styles.withIcon]: icon },
    { [styles.disabled]: disabled },
    className,
  );

  if (onClick) {
    return (
      <button className={cls} onClick={onClick} disabled={disabled} {...props}>
        <ButtonInner icon={icon}>{children}</ButtonInner>
      </button>
    );
  }

  if (to) {
    return (
      <InternalExternalLink className={cls} to={to} {...props}>
        <ButtonInner icon={icon}>{children}</ButtonInner>
      </InternalExternalLink>
    );
  }

  return (
    <span className={cls} {...props}>
      <ButtonInner icon={icon}>{children}</ButtonInner>
    </span>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  className: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'normal', 'large']),
};

export { Button };

export const getTagsFromPost = post => {
  const arr = [];

  for (let i = 0; i < post.node.tags.nodes.length; i++) {
    arr[i] = post.node.tags.nodes[i].name;
  }

  return arr;
};

export const getImageSrc = image => {
  return image?.localFile?.childImageSharp?.fluid?.src || image?.localFile?.publicURL;
};

export const getFeaturedImageSrc = featuredImage => {
  return featuredImage?.node?.localFile?.childImageSharp?.fluid?.src || featuredImage?.node?.localFile?.publicURL;
};

export const getOrderedCategoryPosts = posts => {
  const arr = [];
  const tempPosts = [...posts];

  while (tempPosts.length > 0) {
    let index;
    let smallest = 100000000;

    for (let i = 0; i < tempPosts.length; i++) {
      if (tempPosts[i].postOrder?.order) {
        if (tempPosts[i].postOrder.order < smallest) {
          smallest = tempPosts[i].postOrder.order;
          index = i;
        }
      } else {
        index = 0;
      }
    }
    arr.push(...tempPosts.splice(index, 1));
  }

  return arr;
};

export const getPostCategory = categories => {
  return {
    slug: categories.nodes[0]?.slug,
    comingSoon: categories.nodes[0]?.categoryExtraFields?.comingSoon,
  };
};

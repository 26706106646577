import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as LogoSvg } from '../../../images/logo.svg';
import { ReactComponent as BlobSvg } from '../../../images/svg/blob.svg';

import { TextSvg } from '../../common/TextSvg';
import { Wrapper } from '../Wrapper';
import { Icon } from '../../common/Icon/Icon';
import { useAppContext } from '../../context/AppContext';
import { clickOutside } from '../../../utils/common';
import styles from './Header.module.scss';

const Header = ({ className, location, alt }) => {
  const menuRef = useRef(null);
  // const { toggleBreak, toggleEvents } = useAppContext();
  const { toggleBreak } = useAppContext();
  const [menuOpen, setMenuOpen] = useState(false);
  const isLargeMobile = useMediaQuery({
    query: '(min-width: 35em)',
  });

  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  useEffect(() => {
    if (menuOpen) {
      menuRef.current.focus();

      const unlisten = clickOutside(menuRef.current, () => {
        setMenuOpen(false);
      });

      return () => unlisten();
    }
  }, [menuOpen]);

  return (
    <header className={classNames(styles.header, { [styles.headerOpen]: menuOpen }, { [styles.alt]: alt }, className)}>
      <Wrapper className={styles.inner}>
        <Link to="/" className={styles.logo}>
          <span className="visually-hidden">home</span>
          <LogoSvg />
        </Link>
        {/* 
        {isLargeMobile && ( */}
        <div className={styles.middle}>
          <TextSvg
            tagEl="button"
            onClick={() => toggleBreak(true)}
            className={styles.break}
            innerClassName={styles.breakInner}
            svg={BlobSvg}
            colorHover
          >
            Take a break?
          </TextSvg>
        </div>
        {/* )} */}

        <div className={styles.menuArea}>
          <button className={styles.menuBtn} onClick={() => setMenuOpen(true)} aria-label="menu">
            <span className={styles.menuBtnLine} />
            <span className={styles.menuBtnLine} />
            <span className={styles.menuBtnLine} />
            <span className="visually-hidden">menu</span>
          </button>

          <div ref={menuRef} className={styles.menu}>
            <ul>
              <li>
                <Link activeClassName={styles.activeLink} to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link activeClassName={styles.activeLink} to="/explore">
                  Explore
                </Link>
              </li>
              {/* <li>
                <button onClick={() => toggleEvents(true)}>Events</button>
              </li> */}
              <li>
                <Link activeClassName={styles.activeLink} to="/about">
                  About
                </Link>
              </li>
              {!isLargeMobile && (
                <li>
                  <button onClick={() => toggleBreak(true)}>Take a break</button>
                </li>
              )}
            </ul>
            <div className={styles.menuClose}>
              <button onClick={() => setMenuOpen(false)}>
                <Icon name="x" />
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
    </header>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export { Header };

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ReactComponent as ArticleIcon } from './icons/article.svg';
import { ReactComponent as ChevronDownIcon } from './icons/chevron-down.svg';
import { ReactComponent as ChevronRightIcon } from './icons/chevron-right.svg';
import { ReactComponent as ClickIcon } from './icons/click.svg';
import { ReactComponent as ListeningIcon } from './icons/listening.svg';
import { ReactComponent as PdfIcon } from './icons/pdf.svg';
import { ReactComponent as PdfAccessibleIcon } from './icons/pdf-accessible.svg';
import { ReactComponent as PlayIcon } from './icons/play.svg';
import { ReactComponent as PauseIcon } from './icons/pause.svg';
import { ReactComponent as SurveyIcon } from './icons/survey.svg';
import { ReactComponent as VideoIcon } from './icons/video.svg';
import { ReactComponent as XIcon } from './icons/x.svg';
import { ReactComponent as ArrowDrawnIcon } from './icons/arrow-drawn.svg';
import { ReactComponent as ArrowDrawnDownIcon } from './icons/arrow-drawn-down.svg';
import { ReactComponent as AudioIcon } from './icons/audio.svg';
import { ReactComponent as CalendarIcon } from './icons/calendar.svg';
import { ReactComponent as ClockIcon } from './icons/clock.svg';
import { ReactComponent as CloseIcon } from './icons/close.svg';
import { ReactComponent as DownloadIcon } from './icons/download.svg';
import { ReactComponent as ImageIcon } from './icons/image.svg';
import { ReactComponent as InstagramIcon } from './icons/instagram.svg';
import { ReactComponent as NewsletterIcon } from './icons/newsletter.svg';
import { ReactComponent as TwitterIcon } from './icons/twitter.svg';
import { ReactComponent as VolumeOffIcon } from './icons/volume-off.svg';
import { ReactComponent as VolumeOnIcon } from './icons/volume-on.svg';
import { ReactComponent as BenClose } from './icons/ben-close.svg';
import { ReactComponent as BenCloseActive } from './icons/ben-close-active.svg';
import { ReactComponent as Shop } from './icons/shop.svg';

import styles from './Icon.module.scss';

const iconMap = {
  'ben-close': BenClose,
  'ben-close-active': BenCloseActive,
  article: ArticleIcon,
  'chevron-down': ChevronDownIcon,
  'chevron-right': ChevronRightIcon,
  click: ClickIcon,
  listening: ListeningIcon,
  pdf: PdfIcon,
  'pdf-accessible': PdfAccessibleIcon,
  play: PlayIcon,
  survey: SurveyIcon,
  video: VideoIcon,
  x: XIcon,
  pause: PauseIcon,
  'arrow-drawn': ArrowDrawnIcon,
  'arrow-drawn-down': ArrowDrawnDownIcon,
  audio: AudioIcon,
  calendar: CalendarIcon,
  clock: ClockIcon,
  close: CloseIcon,
  download: DownloadIcon,
  image: ImageIcon,
  instagram: InstagramIcon,
  newsletter: NewsletterIcon,
  twitter: TwitterIcon,
  'volume-off': VolumeOffIcon,
  'volume-on': VolumeOnIcon,
  shop: Shop,
};

export const iconKeys = Object.keys(iconMap);
export const iconSizes = ['free', 'small', 'medium', 'large'];

const Icon = ({ name, size = 'medium', className, stroke, flip, mirror, ...props }) => {
  const cls = classNames(
    styles.icon,
    styles[size],
    { [styles.flip]: flip },
    { [styles.mirror]: mirror },
    { [styles.stroke]: stroke },
    className,
  );

  // Retrieve the icon from our icon object
  // CamelCase variable tells jsx itll be a component
  const El = iconMap[name];

  // If for whatever reason the name passed doesn't
  // exist, eject the icon to not error
  if (!El) {
    return null;
  }

  return (
    <span className={cls} {...props}>
      <El />
    </span>
  );
};

Icon.propTypes = {
  name: PropTypes.oneOf(iconKeys).isRequired,
  size: PropTypes.oneOf(iconSizes),
  className: PropTypes.string,
};

export { Icon };

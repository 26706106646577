import React, { useEffect, useRef, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from '../Icon/Icon';
import { registerExits } from '../../../utils/common';

import styles from './Overlay.module.scss';

let singletonPortalId = 1;

const Overlay = ({
  active,
  removeTimeout = 500,
  portalId,
  backdrop = true,
  className,
  backdropClassName,
  contentClassName,
  children,
  onClose,
  lockScroll = true,
}) => {
  const [internalActive, setInternalActive] = useState(active);
  const portalIdRef = useRef(portalId || `portal-${singletonPortalId}`);
  const portalRef = useRef(null);
  const [, setInternalUpdate] = useState(0);
  const unlistenRef = useRef();

  useEffect(() => {
    singletonPortalId++;
    setInternalUpdate(state => state + 1);

    const el = document.getElementById(portalIdRef.current);

    if (!el) {
      const d = document.createElement('div');
      d.id = portalIdRef.current;
      d.classList.add(styles.overlay, className);
      document.body.appendChild(d);
      d.tabIndex = 0;

      portalRef.current = d;
      portalRef.current.classList.toggle(styles.show, active);
    } else {
      portalRef.current = el;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (internalActive !== active) {
      portalRef.current.classList.toggle(styles.show, active);
      if (active) {
        setInternalActive(true);

        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            if (lockScroll) {
              disableBodyScroll(portalRef.current);
            }
            portalRef.current.focus();
          });
        });

        unlistenRef.current = registerExits(() => {
          onClose();
        });
      } else {
        unlistenRef.current && unlistenRef.current();
        const timer = setTimeout(() => {
          if (lockScroll) {
            enableBodyScroll(portalRef.current);
          }
          setInternalActive(false);
        }, removeTimeout);

        return () => clearTimeout(timer);
      }
    }
  }, [active, internalActive, onClose, removeTimeout, lockScroll]);

  if (!internalActive || !portalRef.current) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      {backdrop && <div onClick={onClose} className={classNames(styles.backdrop, backdropClassName)} />}
      <div className={classNames(styles.content, contentClassName)}>{children}</div>
    </>,
    portalRef.current,
  );
};

const OverlayClose = ({ onClick, className = '', ...props }) => {
  return (
    <button className={classNames(styles.close, className)} onClick={onClick} {...props}>
      <Icon name="close" size="free" />
    </button>
  );
};

Overlay.Close = OverlayClose;

Overlay.propTypes = {
  active: PropTypes.bool,
  removeTimeout: PropTypes.number,
  portalId: PropTypes.string,
  backdrop: PropTypes.bool,
  className: PropTypes.string,
  backdropClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { Overlay };

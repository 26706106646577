/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import CookieConsent from 'react-cookie-consent';
import classNames from 'classnames';
import { NON_LAYOUT } from '../../../utils/constants.js';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { HelpButton } from '../HelpOverlay/HelpButton';

import styles from './Layout.module.scss';

const Layout = ({ children, location }) => {
  //test against a list of "app" endpoints in the constants
  const appLayout = NON_LAYOUT.some(pathname => location.pathname.includes(pathname));

  return (
    <>
      {!appLayout && <Header location={location} alt={location.pathname === '/explore'} />}
      <main
        className={classNames(
          styles.main,
          { [styles.noPad]: location.pathname === '/explore' },
          { [styles.appLayout]: appLayout },
        )}
      >
        {children}
      </main>

      {!appLayout && (
        <>
          <HelpButton />
          <Footer hide={location.pathname === '/explore'} />
        </>
      )}

      <CookieConsent
        location="bottom"
        buttonText="YES"
        declineButtonText="NO"
        enableDeclineButton
        flipButtons
        acceptOnScroll
        acceptOnScrollPercentage={30}
        cookieName="gatsby-gdpr-google-analytics"
        disableStyles
        buttonClasses={styles.cookieButton}
        declineButtonClasses={styles.cookieButton}
        containerClasses={styles.cookieContainer}
        contentClasses={styles.cookieContent}
      >
        <p>
          <strong>Your choice regarding cookies on this site:</strong> By clicking on <strong>YES</strong>, you agree to
          our use of cookies. We use cookies to provide you with a great experience and to help our website run
          effectively.
        </p>
      </CookieConsent>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
};

export default Layout;

import React from 'react';
import classNames from 'classnames';

import { Icon } from '../Icon/Icon';
import { Button } from '../Button';
import { InternalExternalLink } from '../InternalExternalLink';
import styles from './NewsletterBox.module.scss';

const NewsletterBox = ({ className }) => {
  return (
    <div className={classNames(styles.box, className)}>
      <div className={styles.icon}>
        <Icon name="newsletter" className={styles.iconEl} />
      </div>
      <div className={styles.info}>
        <span className={styles.title}>Join our mailing&nbsp;list</span>
        <Button
          to="https://heartnsoul.us3.list-manage.com/subscribe?u=59102db0bd8323d28f5bb44c2&id=68c03e4210"
          className={styles.btn}
        >
          Sign up
        </Button>

        <p className={styles.meta}>
          View our <InternalExternalLink to="/privacy">privacy policy</InternalExternalLink>
        </p>
      </div>
    </div>
  );
};

export { NewsletterBox };

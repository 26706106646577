import React, { useCallback, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { clearAllBodyScrollLocks, enableBodyScroll } from 'body-scroll-lock';
import { Overlay } from '../../common/Overlay';
// import EventImage from '../../../images/test/event.png';
import { getFeaturedImageSrc } from '../../../utils/data';
import { EventItem } from './EventItem';

import styles from './EventsOverlay.module.scss';

const EventsOverlay = ({ active, onClose }) => {
  const data = useStaticQuery(graphql`
    {
      allWpHubEvent {
        edges {
          node {
            eventData {
              displayDate
              displayTime
              eventBookingLink
              eventShortDescription
              eventEndDate
            }
            title
            featuredImage {
              node {
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 640) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const events = data.allWpHubEvent.edges;

  const eventsArr = useMemo(() => {
    return events.map((event, index) => {
      return {
        date: event.node.eventData.displayDate,
        endDate: event.node.eventData.eventEndDate,
        time: event.node.eventData.displayTime,
        title: event.node.title,
        description: event.node.eventData.eventShortDescription,
        link: event.node.eventData.eventBookingLink,
        image: getFeaturedImageSrc(event.node.featuredImage),
      };
    });
  }, [events]);

  const onScrollRef = useCallback(ref => {
    if (ref) {
      enableBodyScroll(ref);
    } else {
      clearAllBodyScrollLocks();
    }
  }, []);

  return (
    <Overlay
      lockScroll={false}
      className={styles.overlay}
      active={active}
      onClose={onClose}
      contentClassName={styles.content}
    >
      <Overlay.Close onClick={onClose} />
      <div ref={onScrollRef} className={styles.scroll}>
        <div className={styles.table}>
          {eventsArr.map((event, index) => (
            <EventItem className={styles.item} {...event} key={`event-${index}`} />
          ))}
        </div>
      </div>
    </Overlay>
  );
};

export { EventsOverlay };

import { useState, useEffect } from 'react';

const checkIsSafari = () => {
  let is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
  let is_safari = navigator.userAgent.indexOf('Safari') > -1;

  if (is_chrome && is_safari) {
    is_safari = false;
  }

  return is_safari;
};

export default function useIsSafari() {
  const [isSafari, setIsSafari] = useState();

  useEffect(() => {
    setIsSafari(checkIsSafari());
  }, []);

  return isSafari;
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import WellcomeLogo from '../../../images/footer/wellcome.png';

import { Wrapper } from '../Wrapper';
import { Icon } from '../../common/Icon/Icon';
import { InternalExternalLink } from '../../common/InternalExternalLink';
import { NewsletterBox } from '../../common/NewsletterBox';
import { HandwrittenTitle } from '../../common/HandwrittenTitle';

import styles from './Footer.module.scss';

const SOCIALS = [
  {
    type: 'twitter',
    link: 'https://twitter.com/HeartnSoulEye',
  },
  {
    type: 'instagram',
    link: 'https://www.instagram.com/heartnsouleye/',
  },
];

const Footer = ({ className, hide }) => {
  return (
    <footer className={classNames(styles.footer, { [styles.hide]: hide }, className)}>
      <Wrapper className={styles.inner}>
        <div className={styles.left}>
          <div className={styles.logos}>
            <a className={styles.padLink} href="https://wellcome.org/" target="_blank" rel="noreferrer noopener">
              <img src={WellcomeLogo} alt="Wellcome trust website" />
            </a>
          </div>

          <p className={styles.tagline}>Heart n Soul at The Hub was funded by Wellcome</p>

          <p className={styles.meta}>
            Heart n Soul Charity registration number: 1023467 Company Limited number: 2830733
          </p>
        </div>
        <div className={styles.right}>
          <HandwrittenTitle className={styles.touch} title="Stay in touch" />
          {/* <Title size="large">Stay in touch</Title> */}

          <ul className={styles.socials}>
            {SOCIALS.map(item => (
              <li key={item.type}>
                <InternalExternalLink className={styles.padLink} to={item.link}>
                  <Icon name={item.type} size="large" />
                  <span className="visually-hidden">{item.type}</span>
                </InternalExternalLink>
              </li>
            ))}
          </ul>

          <NewsletterBox />
        </div>
      </Wrapper>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export { Footer };

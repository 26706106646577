import React from 'react';
import { Overlay } from '../../common/Overlay';
import { VideoEmbed } from '../../pagelayout/article/VideoCarousel/VideoEmbed';

import styles from './HelpOverlay.module.scss';

const HelpOverlay = ({ active, onClose }) => {
  return (
    <Overlay backdrop={false} active={active} onClose={onClose} contentClassName={styles.content}>
      <Overlay.Close onClick={onClose} />

      <div className={styles.plate}>
        <VideoEmbed className={styles.embed} url="https://vimeo.com/499724555" />
      </div>
    </Overlay>
  );
};

export { HelpOverlay };

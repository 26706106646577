import React from 'react';

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './src/styles/variables/colors.css';
import './src/styles/variables/general.css';
import './src/styles/variables/sizes.css';
import './src/styles/variables/type.css';
import './src/styles/global.scss';

import Layout from './src/components/layout/Layout';
import { AppContextContainer } from './src/components/context/AppContext';

export const wrapPageElement = ({ element, props }) => {
  return <Layout location={props.location}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => {
  return <AppContextContainer>{element}</AppContextContainer>;
};

export const onServiceWorkerUpdateReady = () => {
  setTimeout(() => {
    window.dispatchEvent(new Event('sw-updated'));
  }, 1000);
};

import React, { useMemo } from 'react';
import classNames from 'classnames';
// import { format } from 'date-fns';
import { Icon } from '../../common/Icon/Icon';
import { Button } from '../../common/Button';
import { InternalExternalLink } from '../../common/InternalExternalLink';

import { ReactComponent as EventSvg } from '../../../images/svg/event.svg';
import { ReactComponent as EventReverseSvg } from '../../../images/svg/event-reverse.svg';
import useIsSafari from '../../../utils/hooks/isSafari';

import styles from './EventItem.module.scss';

const EventItem = ({ link, title, date, time, endDate, description, className, image, small = false }) => {
  const isSafari = useIsSafari();

  const isPast = useMemo(() => {
    return new Date(`${endDate} 2021`).getTime() < new Date().getTime();
  }, [endDate]);
  return (
    <InternalExternalLink
      to={link}
      className={classNames(styles.item, { [styles.small]: small }, { [styles.safari]: isSafari }, className)}
    >
      {small && <EventSvg className={styles.bg} preserveAspectRatio="none" />}
      {image && !small && <div className={styles.image} style={{ backgroundImage: `url(${image})` }} />}
      {!small && <EventReverseSvg className={styles.bgReverse} preserveAspectRatio="none" />}
      <div className={styles.inner}>
        <div className={styles.content}>
          <span className={styles.title}>{title}</span>

          <ul className={styles.info}>
            <li>
              <Icon name="calendar" className={styles.infoIcon} />
              {/* <span className={styles.infoText}>{format(date, 'eeee do MMMM')}</span> */}
              <span className={styles.infoText}>{date}</span>
            </li>
            <li>
              <Icon name="clock" className={styles.infoIcon} />
              <span className={styles.infoText}>{time}</span>
            </li>
          </ul>

          {!small && (
            <div className={styles.description}>
              <p>{description}</p>
            </div>
          )}

          {!isPast && (
            <Button size={small ? 'normal' : 'large'} className={styles.btn}>
              Book
            </Button>
          )}
        </div>
      </div>
    </InternalExternalLink>
  );
};

export { EventItem };

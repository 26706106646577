/* eslint-disable no-unused-expressions */
// document.body etc is unused expression
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { BreakOverlay } from '../layout/BreakOverlay/index.js';
import { EventsOverlay } from '../layout/EventsOverlay/index.js';
import { HelpOverlay } from '../layout/HelpOverlay/index.js';

const AppContext = createContext({
  swUpdated: false,
  currentTag: 'none',
});

export const getCategoryColor = str => {
  switch (str) {
    case 'Flipping the microscope':
      return 'cat-colour-rose';
    case 'People are different - go with it':
      return 'cat-colour-green';
    case 'The world as we see it':
      return 'cat-colour-purple';
    case "We're all just human":
      return 'cat-colour-blue';
    case 'plain':
      return 'bg-plain';
    case 'none':
    default:
      return 'cat-colour-default';
  }
};

const AppContextContainer = ({ children }) => {
  const [muteInteractions, setMuteInteractions] = useState(true);
  const [swUpdated, setSwUpdated] = useState(false);
  const [currentTag, setCurrentTag] = useState('none');
  const [helpOverlay, setHelpOverlay] = useState(false);
  const [eventsOverlay, setEventsOverlay] = useState(false);
  const [breakOverlay, setBreakOverlay] = useState(false);

  const updateCurrentTag = useCallback(value => {
    setCurrentTag(value);
  }, []);

  useEffect(() => {
    const cb = () => {
      setSwUpdated(true);
    };

    window.addEventListener('sw-updated', cb);

    return () => {
      window.removeEventListener('sw-updated', cb);
    };
  }, []);

  const toggleEvents = useCallback(force => {
    setEventsOverlay(events => (typeof force === 'boolean' ? force : !events));
  }, []);

  const toggleBreak = useCallback(force => {
    setBreakOverlay(breakState => (typeof force === 'boolean' ? force : !breakState));
  }, []);

  const toggleHelp = useCallback(force => {
    setHelpOverlay(helpState => (typeof force === 'boolean' ? force : !helpState));
  }, []);

  const toggleMute = useCallback(force => {
    setMuteInteractions(muteState => (typeof force === 'boolean' ? force : !muteState));
  }, []);

  const value = {
    swUpdated,
    muteInteractions,
    currentTag,
    updateCurrentTag,
    toggleEvents,
    toggleBreak,
    toggleHelp,
    toggleMute,
  };

  return (
    <AppContext.Provider value={value}>
      {children}

      <EventsOverlay active={eventsOverlay} onClose={() => setEventsOverlay(false)} />
      <BreakOverlay active={breakOverlay} onClose={() => setBreakOverlay(false)} />
      <HelpOverlay active={helpOverlay} onClose={() => setHelpOverlay(false)} />
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
export { AppContextContainer };

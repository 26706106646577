import React, { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as Title001 } from './assets/category-flipping-the-microscope-narrow.svg';
import { ReactComponent as Title002 } from './assets/category-flipping-the-microscope-wide.svg';
import { ReactComponent as Title002a } from './assets/category-flipping-the-microscope-alt.svg';
import { ReactComponent as Title003 } from './assets/category-people-are-different-go-with-it-narrow.svg';
import { ReactComponent as Title004 } from './assets/category-people-are-different-go-with-it-wide.svg';
import { ReactComponent as Title004a } from './assets/category-people-are-different-go-with-it-alt.svg';
import { ReactComponent as Title005 } from './assets/category-the-world-as-we-see-it-narrow.svg';
import { ReactComponent as Title006 } from './assets/category-the-world-as-we-see-it-wide.svg';
import { ReactComponent as Title006a } from './assets/category-the-world-as-we-see-it-alt.svg';
import { ReactComponent as Title007 } from './assets/category-were-all-just-human-narrow.svg';
import { ReactComponent as Title008 } from './assets/category-were-all-just-human-wide.svg';
import { ReactComponent as Title008a } from './assets/category-were-all-just-human-alt.svg';
import { ReactComponent as Title009 } from './assets/title-a-different-kind-of-future.svg';
import { ReactComponent as Title010 } from './assets/title-any-sound-you-like.svg';
import { ReactComponent as Title011 } from './assets/title-can-we-all-be-designers.svg';
import { ReactComponent as Title012 } from './assets/title-can-we-all-be-researchers.svg';
import { ReactComponent as Title013 } from './assets/title-creating-space-for-everyone.svg';
import { ReactComponent as Title014 } from './assets/title-designing-a-space-for-everyone.svg';
import { ReactComponent as Title015 } from './assets/title-explore.svg';
import { ReactComponent as Title016 } from './assets/title-how-we-make-sense-of-our-data.svg';
import { ReactComponent as Title017 } from './assets/title-is-how-you-feel-as-import-as-what-you-think.svg';
import { ReactComponent as Title018 } from './assets/title-life-in-lockdown.svg';
import { ReactComponent as Title019 } from './assets/title-making-research-more-human.svg';
import { ReactComponent as Title020 } from './assets/title-relax-and-feel-smooth.svg';
import { ReactComponent as Title021 } from './assets/title-stay-in-touch.svg';
import { ReactComponent as Title022 } from './assets/title-we-all-need-care.svg';
import { ReactComponent as Title023 } from './assets/title-what-did-we-see-when-we-saw-you.svg';
import { ReactComponent as Title024 } from './assets/title-what-do-you-see-when-you-see-me.svg';
import { ReactComponent as Title025 } from './assets/title-what-does-productivity-mean-to-you.svg';

import { ReactComponent as Title026 } from './assets/artwork-title-a-world-that-is.svg';
import { ReactComponent as Title027 } from './assets/artwork-title-hopes-and-dreams.svg';
import { ReactComponent as Title028 } from './assets/artwork-title-inclusive-futures.svg';
import { ReactComponent as Title029 } from './assets/artwork-title-play-together.svg';

import styles from './HandwrittenTitle.module.scss';

const assets = {
  'category-flipping-the-microscope-narrow': Title001,
  'category-flipping-the-microscope-wide': Title002,
  'category-flipping-the-microscope-alt': Title002a,
  'category-people-are-different-go-with-it-narrow': Title003,
  'category-people-are-different-go-with-it-wide': Title004,
  'category-people-are-different-go-with-it-alt': Title004a,
  'category-the-world-as-we-see-it-narrow': Title005,
  'category-the-world-as-we-see-it-wide': Title006,
  'category-the-world-as-we-see-it-alt': Title006a,
  'category-were-all-just-human-narrow': Title007,
  'category-were-all-just-human-wide': Title008,
  'category-were-all-just-human-alt': Title008a,
  'title-a-different-kind-of-future': Title009,
  'title-any-sound-you-like': Title010,
  'title-can-we-all-be-designers': Title011,
  'title-can-we-all-be-researchers': Title012,
  'title-creating-space-for-everyone': Title013,
  'title-designing-a-space-for-everyone': Title014,
  'title-explore': Title015,
  'title-how-we-make-sense-of-our-data': Title016,
  'title-is-how-you-feel-as-import-as-what-you-think': Title017,
  'title-life-in-lockdown': Title018,
  'title-making-research-more-human': Title019,
  'title-relax-and-feel-smooth': Title020,
  'title-stay-in-touch': Title021,
  'title-we-all-need-care': Title022,
  'title-what-did-we-see-when-we-saw-you': Title023,
  'title-what-do-you-see-when-you-see-me': Title024,
  'title-what-does-productivity-mean-to-you': Title025,

  'artwork-title-a-world-that-is': Title026,
  'artwork-title-hopes-and-dreams': Title027,
  'artwork-title-inclusive-futures': Title028,
  'artwork-title-play-together': Title029,
};

const text = {
  'Flipping the microscope': 'category-flipping-the-microscope',
  'People are different - go with it': 'category-people-are-different-go-with-it',
  'The world as we see it': 'category-the-world-as-we-see-it',
  "We're all just human": 'category-were-all-just-human',
  'A different kind of future': 'title-a-different-kind-of-future',
  'Any sound you like': 'title-any-sound-you-like',
  'Can we all be designers?': 'title-can-we-all-be-designers',
  'Can we all be researchers?': 'title-can-we-all-be-researchers',
  'Creating space for everyone': 'title-creating-space-for-everyone',
  'Designing a space for everyone': 'title-designing-a-space-for-everyone',
  Explore: 'title-explore',
  'How we made sense of our data': 'title-how-we-make-sense-of-our-data',
  'Is how you feel as important as what you think?': 'title-is-how-you-feel-as-import-as-what-you-think',
  'Life in lockdown': 'title-life-in-lockdown',
  'Making research more human': 'title-making-research-more-human',
  'Relax and feel smooth': 'title-relax-and-feel-smooth',
  'Stay in touch': 'title-stay-in-touch',
  'We all need care': 'title-we-all-need-care',
  'What did we see when we saw you?': 'title-what-did-we-see-when-we-saw-you',
  'What do you see when you see me?': 'title-what-do-you-see-when-you-see-me',
  'What does productivity mean to you?': 'title-what-does-productivity-mean-to-you',
  'A different kind of future 2': 'title-a-different-kind-of-future',
  'A World That Is, A World That Isn’t': 'artwork-title-a-world-that-is',
  'Hopes and Dreams Tree': 'artwork-title-hopes-and-dreams',
  'Inclusive Futures': 'artwork-title-inclusive-futures',
  'Play Together': 'artwork-title-play-together',
};

const getSuffix = (narrow, alt) => {
  if (alt) {
    return '-alt';
  }

  if (!narrow) {
    return '-wide';
  }

  return '-narrow';
};

const HandwrittenTitle = ({ tagEl: El = 'span', title, responsive, narrow, alt, className, svgClassName }) => {
  const titleKey = useMemo(() => {
    return text[title];
  }, [title]);

  const SvgEl = useMemo(() => {
    const sizeSuffix = getSuffix(narrow, alt);

    const checkKey = responsive ? `${titleKey}${sizeSuffix}` : titleKey;

    if (assets[checkKey]) {
      return assets[checkKey];
    }

    return null;
  }, [narrow, responsive, titleKey, alt]);

  if (!SvgEl) {
    return <El className={classNames(styles.title, styles.text, className)}>{title}</El>;
  }

  return (
    <El className={classNames(styles.title, className)}>
      <SvgEl className={svgClassName} />
      <span className="visually-hidden">{title}</span>
    </El>
  );
};

HandwrittenTitle.propTypes = {
  responsive: PropTypes.bool,
  title: PropTypes.oneOf(Object.keys(text)),
};

export { HandwrittenTitle };

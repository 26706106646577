import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from '../../../common/Icon/Icon';
import styles from './VideoCarousel.module.scss';

const getVimeoId = url => {
  const regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;

  const match = url.match(regExp);

  if (match) {
    return match[2];
  } else {
    return false;
  }
};

const VideoEmbed = ({ url, active, className, alt, poster }) => {
  const [playing, setPlaying] = useState(false);

  const data = useMemo(() => {
    if (!url) {
      return false;
    }

    if (url.includes('vimeo.com')) {
      return {
        source: 'vimeo',
        url,
        embedUrl: `https://player.vimeo.com/video/${getVimeoId(url)}`,
      };
    }
  }, [url]);

  useEffect(() => {
    if (!active) {
      setPlaying(false);
    }
  }, [active]);

  if (data) {
    return (
      <div className={classNames(styles.embed, { [styles.playing]: playing }, className)}>
        {poster && (
          <div
            onClick={() => setPlaying(true)}
            className={styles.poster}
            role="button"
            tabIndex="0"
            style={{ backgroundImage: `url(${poster})` }}
          >
            <Icon className={styles.posterIcon} name="play" size="large" />
            {alt && <span className={styles.posterText}>{alt}</span>}
          </div>
        )}
        {data.source === 'vimeo' && (
          <iframe
            frameBorder="0"
            className={styles.player}
            title={alt}
            src={data.embedUrl}
            allowFullScreen
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
          />
        )}
      </div>
    );
  }

  return null;
};

VideoEmbed.propTypes = {
  url: PropTypes.string.isRequired,
  active: PropTypes.bool,
  className: PropTypes.string,
  poster: PropTypes.string,
};

export { VideoEmbed };

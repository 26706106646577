import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';
import Obfuscate from 'react-obfuscate';

const InternalExternalLink = ({ to = '', className, children, newTab }) => {
  const cls = classNames('link', className);

  if (!to) {
    return <span className={cls}>{children}</span>;
  }

  if (to.startsWith('http') || to.includes('.pdf') || to.includes('.docx')) {
    return (
      <a className={cls} href={to} target="_blank" rel="noreferrer noopener">
        {children}
      </a>
    );
  }

  if (to?.startsWith('mailto:')) {
    return (
      <Obfuscate className={cls} email={to?.substring('mailto:'.length)}>
        {children}
      </Obfuscate>
    );
  }

  if (to?.startsWith('tel:')) {
    return (
      <Obfuscate className={cls} tel={to?.substring('tel:'.length)}>
        {children}
      </Obfuscate>
    );
  }

  if (newTab) {
    return (
      <a className={cls} href={to} target={newTab && '_blank'} rel="noreferrer noopener">
        {children}
      </a>
    );
  }

  return (
    <Link className={cls} to={to}>
      {children}
    </Link>
  );
};

InternalExternalLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  newTab: PropTypes.bool,
};

export { InternalExternalLink };

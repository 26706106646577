// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-alice-artwork-js": () => import("./../../../src/pages/alice-artwork.js" /* webpackChunkName: "component---src-pages-alice-artwork-js" */),
  "component---src-pages-ben-artwork-js": () => import("./../../../src/pages/ben-artwork.js" /* webpackChunkName: "component---src-pages-ben-artwork-js" */),
  "component---src-pages-ben-js": () => import("./../../../src/pages/ben.js" /* webpackChunkName: "component---src-pages-ben-js" */),
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-felix-artwork-js": () => import("./../../../src/pages/felix-artwork.js" /* webpackChunkName: "component---src-pages-felix-artwork-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newspaper-js": () => import("./../../../src/pages/newspaper.js" /* webpackChunkName: "component---src-pages-newspaper-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-rob-artwork-js": () => import("./../../../src/pages/rob-artwork.js" /* webpackChunkName: "component---src-pages-rob-artwork-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}


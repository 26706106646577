import React from 'react';
import { Overlay } from '../../common/Overlay';
import { Wrapper } from '../Wrapper';
import { VideoEmbed } from '../../pagelayout/article/VideoCarousel/VideoEmbed';

import styles from './BreakOverlay.module.scss';

const BreakOverlay = ({ active, onClose }) => {
  return (
    <Overlay lockScroll={false} backdrop={false} active={active} onClose={onClose} contentClassName={styles.content}>
      <Overlay.Close onClick={onClose} />
      <Wrapper className={styles.wrapper}>
        <div className={styles.plate}>
          <VideoEmbed className={styles.embed} url="https://vimeo.com/488686400" />
        </div>
      </Wrapper>
    </Overlay>
  );
};

export { BreakOverlay };

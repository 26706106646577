import React from 'react';

import { ReactComponent as BlobSvg } from '../../../images/svg/help-blob.svg';
import { useAppContext } from '../../context/AppContext';

import styles from './HelpOverlay.module.scss';

const HelpButton = () => {
  const { toggleHelp } = useAppContext();

  return (
    <button onClick={() => toggleHelp()} className={styles.btn} aria-label="Help button">
      <BlobSvg />
      <span>?</span>
    </button>
  );
};

export { HelpButton };
